import React from 'react'
import Typewriter from 'react-typewriter'

export default function PortraitOfLRonHubbard({ onComplete }={}) {
  return (
    <div className='poem'>
      <Typewriter
        typing={1}
        minDelay={200}
        onTypingEnd={onComplete}
      >
        <p>You’ve got some believers over your head</p>
        <p>eyes like a dream eater, sunken and dreadful</p>
        <br/>
        <p>Is your eloquence mined as a</p>
        <p>Two-bit coat collar back stare</p>
        <br/>
        <p>Shame is not forsaken</p>
        <p>In the ghost of your mind</p>
        <p>You stretched out your body</p>
        <p>In the shape of a knife,</p>
        <p>pulled back the curtain, and gazed on the whites of my eyes</p>
      </Typewriter>
    </div>
  )
}
