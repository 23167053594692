import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showPoems: false,
  bgClass: 'space',
  visualizer: null,
  modal: null,
  modalTitle: null,
  modalBody: null,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  currentLevel: 'main',
  currentPoem: null,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    hidePoems(state) {
      state.showPoems = false
    },

    showPoems(state) {
      state.showPoems = true
    },

    setBgClass(state, action) {
      state.bgClass = action.payload
    },

    setVisualizer(state, action) {
      state.visualizer = action.payload
    },

    showModal(state, action) {
      if (typeof action.payload === 'string')
        state.modal = action.payload
      else {
        state.modal = action.payload.modal
        state.modalTitle = action.payload.title
        state.modalBody = action.payload.body
      }
    },

    hideModal(state) {
      state.modal = null
    },

    setWindowSize(state, action) {
      state.windowWidth = window.innerWidth
      state.windowHeight = window.innerHeight
    },

    setCurrentLevel(state, action) {
      state.currentLevel = action.payload
    },

    setCurrentPoem(state, action) {
      state.currentPoem = action.payload
    },
  },
})

export const {
  hidePoems,
  showPoems,
  setBgClass,
  setVisualizer,
  showModal,
  hideModal,
  setWindowSize,
  setCurrentLevel,
  setCurrentPoem,
} = appSlice.actions

export default appSlice.reducer

