import React from 'react'
import Typewriter from 'react-typewriter'

export default function ListlessAndWet({ onComplete }={}) {
  return (
    <div className='poem'>
      <Typewriter
        typing={1}
        minDelay={200}
        onTypingEnd={onComplete}
      >
        <p>Something solicits me</p>
        <p>To carve my name to the tips of her smile</p>
        <p>To shade myself beneath her chin</p>
        <br/>
        <p>Her hands wave</p>
        <p>Astrological signatures</p>
        <p>The birth of a woman upon her</p>
        <p>Eyebrows raised, ecstatic</p>
        <p>She crumbles beneath god</p>
        <p>Discovering her soul beneath the darks of her eyes</p>
        <br/>
        <p>Something solicits me</p>
        <p>To carve my name into her soft mouth</p>
        <p>Lovingly, but alas</p>
        <br/>
        <p>Her eyes are not for me</p>
        <p>I gaze from afar as they drift to sea</p>
      </Typewriter>
    </div>
  )
}
