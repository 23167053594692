import React from 'react'
import Modal from '../../modal'

export default function RecordStoreModal({ visible }) {
  return (
    <Modal title={'Hi, I\'m Avocado Jesus\'s biggest fan!'} visible={visible} className='in-game'>
      <p>I have been following Avocado Jesus through his wurlitzer journeys to many distant worlds!</p>
      <p>Would you like to see some of the stuff he has been up to?</p>
      <ul>
        <li>
          <a href='https://vimeo.com/245534810' target='_blank'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/videos/dinjam.png)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Watch as his band shredz</span>
          </a>
        </li>
        <li>
          <a href='https://vimeo.com/243279503' target='_blank'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/videos/poemtoacoldstar.png)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Watch Poems to a Cold Star</span>
          </a>
        </li>
        <li>
          <a href='https://vimeo.com/203222677' target='_blank'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/videos/hairsalon.png)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Watch his band jam at a salon</span>
          </a>
        </li>

        <li>
          <a href='https://vimeo.com/237159908' target='_blank'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/videos/soloballade.png)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Watch him lament our planet</span>
          </a>
        </li>

        <li>
          <a href='https://vimeo.com/203222610' target='_blank'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/videos/fleeinghb.png)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Watch him flee the berbs</span>
          </a>
        </li>
      </ul>
    </Modal>
  )
}
