import React from 'react'
import Typewriter from 'react-typewriter'

export default function ListlessAndWet({ onComplete }={}) {
  return (
    <div className='poem'>
      <Typewriter
        typing={1}
        minDelay={200}
        onTypingEnd={onComplete}
      >
        <p>And so it begins</p>
        <p>As she sits there with her eyes sealed</p>
        <p>The curves of her lids dark with pain</p>
        <p>She lights her hair like a cigarette</p>
        <p>Carelessly</p>
        <br/>
        <p>A crimson puke turned cinnamon scowls at a nightingale</p>
        <br/>
        <p>She pulls on the sides of her waist</p>
        <p>Imagines herself thinner, bruised up the side</p>
        <p>Calls to herself</p>
        <p>Cradled in stone</p>
        <br/>
        <p>A crimson puke turned cinnamon scowls at a nightingale</p>
        <br/>
        <p>Tried and true</p>
        <p>Tried and true</p>
        <p>Black and blue</p>
        <p>Black and blue</p>
        <p>Maybe he will change</p>
      </Typewriter>
    </div>
  )
}
