import React from 'react'
import { useDispatch } from 'react-redux'
import { hideModal as hideModalAction } from '../../slices/app'

export default function Modal({ title, children, visible, className }={ visible: false }) {
  const dispatch = useDispatch()
  const hideModal = () => {
    document.body.dispatchEvent(new CustomEvent('hideModal'))
    dispatch(hideModalAction())
  }

  if (!visible) return null
  return (
    <div className={`modal ${className} ${visible ? 'visible' : ''}`}>
      <div className='modal-backdrop' onClick={hideModal}></div>
      <div className='inner'>
        <h2>{title}</h2>
        <div className='body'>{children}</div>
        <div className='actions'>
          <button
            onClick={hideModal}
          >Close</button>
        </div>
      </div>
    </div>
  )
}
