import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

const svgHeight = '300'
const svgWidth = window.innerWidth
let svg
let frequencyData = new Uint8Array(200)

function createSvg(parent, height, width) {
  return d3.select(parent)
    .append('svg')
    .attr('height', height)
    .attr('width', width)
    .attr('class', 'animation-svg')
}

export default function TestPoem() {
  const ref = useRef()

  useEffect(() => {
    svg = createSvg(ref.current, svgHeight, svgWidth)
    svg.selectAll('circle')
      .data(frequencyData)
      .enter()
      .append('circle')
      .attr('fill', 'red')
      .attr('cx', (d, i) => {
         return i * (svgWidth / frequencyData.length)
      })
     .attr('r', 100)
  }, [])

  function draw({ analyser }) {
    if (!svg) return false

    analyser.getByteFrequencyData(frequencyData)
    svg.selectAll('circle')
      .data(frequencyData)
      .attr('r', (d, i) => {
        return d
      })
      .attr('opacity', (d) => {
        return d / 1000
      })
  }

  document.body.addEventListener('non-redux-safe-event::RECORD_PLAYER_TIME_UPDATED', event => {
    draw({ analyser: event.detail.analyser })
  })

  return (
    <div ref={ref}>
    </div>
  )
}

