import React, { useEffect, useState } from 'react'
import Astros from './astros'
import PortraitOfLRonHubbard from './portrait-of-l-ron-hubbard'
import ListlessAndWet from './listless-and-wet'
import ScowlsAtANightingale from './scowls-at-a-nightingale'
import Narcissus from './narcissus'
import SomethingSolicitsMe from './something-solicits-me'
import WhatPiety from './what-piety'

import './index.css'

const poems = [
  Astros,
  PortraitOfLRonHubbard,
  ListlessAndWet,
  ScowlsAtANightingale,
  Narcissus,
  SomethingSolicitsMe,
  WhatPiety,
]
const arrayOfIndexes = [...Array(poems.length).keys()]

export default function Poems() {
  const [ poemIndex, setPoemIndex ] = useState(null)
  const [ availablePoemIndexes, setAvailablePoemIndexes ] = useState(arrayOfIndexes)

  function randomPoem(availablePoemIndexes) {
    const random = availablePoemIndexes[Math.floor(Math.random() * availablePoemIndexes.length)]
    setPoemIndex(random)
    setAvailablePoemIndexes(availablePoemIndexes.filter(i => i !== random))
  }

  useEffect(() => {
    randomPoem(availablePoemIndexes)
  }, [])

  const Poem = poemIndex !== null &&
    poems[poemIndex]

  return (
    <div id='poems'>
      {
        Poem &&
        <Poem
          onComplete={() => {
            setTimeout(() => {
              randomPoem(availablePoemIndexes)
            }, 5000)
          }}
        />
      }
    </div>
  )
}
