const dearEarthSorry = {
  title: 'Dear Earth, Sorry',
  artworkUrl: '/img/album-art/dear-earth-sorry.jpg',
  tracks: [
    {
      title: 'Her Shoulder Be My Nakedness Sender',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/dear-earth-sorry/Her%20Shoulder%20Be%20My%20Nakedness%20Sender.mp3',
      local: '/audio/dear-earth-sorry/her-shoulder-be-my-nakedness-sender.mp3',
    },
    {
      title: 'On Icicles Made of Shoeskates I Scale',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/dear-earth-sorry/On%20Icicles%20Made%20of%20Shoeskates%20I%20Scale.mp3',
      local: '/audio/dear-earth-sorry/on-icicles-made-of-shoeskates-i-scale.mp3',
    },
    {
      title: 'To Reap Me of My Soulbled Eyes',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/dear-earth-sorry/To%20Reap%20Me%20of%20My%20Soulbled%20Eyes.mp3',
      local: '/audio/dear-earth-sorry/to-reap-me-of-my-soulbled-eyes.mp3',
    },
    {
      title: 'My Soul I Do Surrender',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/dear-earth-sorry/My%20Soul%20I%20Do%20Surrender.mp3',
      local: '/audio/dear-earth-sorry/my-soul-i-do-surrender.mp3',
    },
  ]
}

export default dearEarthSorry
