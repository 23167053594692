import Phaser from 'phaser'
import { store } from '../store'
import {
  showModal as showModalAction,
  hideModal as hideModalAction,
} from '../../slices/app'
import configureAnimations from './config/animations'

const MOBILE_WIDTH = 600
const CONTROLS_HEIGHT = 150

export default class Level extends Phaser.Scene {
  get width() {
    return window.innerWidth
  }

  get height() {
    return window.innerHeight
  }

  get mapKey() {
    throw 'Must define in child'
  }

  get frameDimensions() {
    return {
      frameWidth: 32,
      frameHeight: 64,
    }
  }

  get defaultWidth() {
    return window.innerWidth
  }

  get defaultHeight() {
    if (window.innerWidth < MOBILE_WIDTH) return window.innerHeight - CONTROLS_HEIGHT
    return window.innerHeight
  }

  get useStandardHeight() {
    return true
  }

  constructor(key) {
    super(key)
    this.spritesToMonitor = []
    this.modalVisible = false
  }

  preload() {
    this.load.image('all', 'tilesets/all.png')
    this.load.spritesheet('ellie', 'tilesets/nikki.png', this.frameDimensions)
    this.load.spritesheet('aster', 'tilesets/aster.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    this.load.scenePlugin('AnimatedTiles', '/js/vendor/animated-tiles.js', 'animatedTiles', 'animatedTiles')
    this.load.audio('footsteps', ['/tilesets/audio/footsteps.m4a'])
    this.onPreload()
  }

  create() {
    if (!this.matter.add) return

    this.map = this.make.tilemap({
      width: this.width,
      height: this.height,
      key: this.mapKey,
      tileWidth: 32,
      tileHeight: 32,
    })
    this.tileset = this.map.addTilesetImage('all', 'all', 32, 32, 0, 0)
    this.sys.animatedTiles.init(this.map)

    this.ellie = this.matter.add.sprite(42, 96, 'ellie')
    this.ellie.body.collideWorldBounds = true
    this.ellie.depth = 10

    this.aster = this.matter.add.sprite(41, 96, 'aster')
    this.aster.depth = 9

    this.cursors = this.input.keyboard.createCursorKeys()

    this.cameras.main.fadeIn(300, 0, 0, 0)
    this.cameras.main.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels)
    this.matter.world.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels)
    this.cameras.main.startFollow(this.ellie)

    this.input.keyboard.on('keydown-ENTER', () => {
      this.enterKeyPressed()
    })

    this.matter.world.on('collisionstart', (event, bodyA, bodyB) => {
      let otherObj = null
      if (bodyA.gameObject?.texture?.key === 'ellie')
        otherObj = bodyB.gameObject
      else if (bodyB.gameObject?.texture?.key === 'ellie')
        otherObj = bodyA.gameObject

      if (this.spritesToMonitor.includes(otherObj))
        this[otherObj.collisionKey] = true

      if (otherObj && typeof this.onEllieCollisionStart === 'function')
        this.onEllieCollisionStart(otherObj)
    })

    this.matter.world.on('collisionend', (event, bodyA, bodyB) => {
      let otherObj = null
      if (bodyA.gameObject?.texture?.key === 'ellie')
        otherObj = bodyB.gameObject
      else if (bodyB.gameObject?.texture?.key === 'ellie')
        otherObj = bodyA.gameObject

      if (this.spritesToMonitor.includes(otherObj))
        this[otherObj.collisionKey] = false

      if (otherObj && typeof this.onEllieCollisionEnd === 'function')
        this.onEllieCollisionEnd(otherObj)
    })

    configureAnimations(this)
    this.startMonsterMovementInterval()

    if (this.useStandardHeight) {
      document.querySelector('#app > canvas').classList.remove('non-standard-height')
      this.scale.resize(this.defaultWidth, this.defaultHeight)
    } else {
      document.querySelector('#app > canvas').classList.add('non-standard-height')
    }

    document.body.addEventListener('moveLeft', this.moveLeftDPad)
    document.body.addEventListener('moveRight', this.moveRightDPad)
    document.body.addEventListener('moveUp', this.moveUpDPad)
    document.body.addEventListener('moveDown', this.moveDownDPad)
    document.body.addEventListener('enter', this.enterKeyPressed)
    document.body.addEventListener('hideModal', this.modalClosed)

    this.footstepSound = this.sound.add("footsteps", { loop: false, volume: 0.3 })

    this.onCreate()
  }

  changeScene = scene => {
    this.changingScene = true
    document.body.dispatchEvent(
      new CustomEvent('sceneChange', { detail: { scene } })
    )

    document.body.removeEventListener('moveLeft', this.moveLeftDPad)
    document.body.removeEventListener('moveRight', this.moveRightDPad)
    document.body.removeEventListener('moveUp', this.moveUpDPad)
    document.body.removeEventListener('moveDown', this.moveDownDPad)
    document.body.removeEventListener('enter', this.enterKeyPressed)
    document.body.removeEventListener('hideModal', this.modalClosed)

    this.cameras.main.fadeOut(300, 0, 0, 0)
    this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, (cam, effect) => {
      this.scene.start(scene)
      this.changingScene = false
    })
  }

  update() {
    if (this.cursors.left.isDown && !this.moveLeftInterval) {
      this.moveLeftInterval = setInterval(this.moveLeft, 100)
    } else if (this.cursors.right.isDown && !this.moveRightInterval) {
      this.moveRightInterval = setInterval(this.moveRight, 100)
    } else if (this.cursors.up.isDown && !this.moveUpInterval) {
      this.moveUpInterval = setInterval(this.moveUp, 100)
    } else if (this.cursors.down.isDown && !this.moveDownInterval) {
      this.moveDownInterval = setInterval(this.moveDown, 100)
    }

    else if (this.cursors.left.isUp && this.moveLeftInterval) {
      clearInterval(this.moveLeftInterval)
      this.moveLeftInterval = null
      setTimeout(() => {
        if (!this.ellie.body) return
        this.idleLeft()
      }, 100)

    } else if (this.cursors.right.isUp && this.moveRightInterval) {
      clearInterval(this.moveRightInterval)
      this.moveRightInterval = null
      setTimeout(() => {
        if (!this.ellie.body) return
        this.idleRight()
      }, 100)

    } else if (this.cursors.up.isUp && this.moveUpInterval) {
      clearInterval(this.moveUpInterval)
      this.moveUpInterval = null
      setTimeout(() => {
        if (!this.ellie.body) return
        this.idleUp()
      }, 100)

    } else if (this.cursors.down.isUp && this.moveDownInterval) {
      clearInterval(this.moveDownInterval)
      this.moveDownInterval = null
      setTimeout(() => {
        if (!this.ellie.body) return
        this.idleDown()
      }, 100)
    }
  }

  enterKeyPressed = () => {
    if (this.modalVisible) {
      store.dispatch(hideModalAction())
      this.modalClosed()

    } else {
      this.onEnter()
    }
  }

  showModal(title, body) {
    this.modalVisible = true

    store.dispatch(showModalAction({
      modal: 'inGame',
      title,
      body,
    }))
  }

  showCustomModal(modalKey) {
    this.modalVisible = true
    store.dispatch(showModalAction(modalKey))
  }

  modalClosed = () => {
    this.modalVisible = false
  }

  idleLeft = () => {
    this.ellie.anims.play('idleLeft')
    this.aster.anims.play('idleAsterLeft')
  }

  idleRight = () => {
    this.ellie.anims.play('idleRight')
    this.aster.anims.play('idleAsterRight')
  }

  idleUp = () => {
    this.ellie.anims.play('idleUp')
    this.aster.anims.play('idleAsterUp')
  }

  idleDown = () => {
    if (this.changingScene) return
    this.ellie.anims.play('idleDown')
    this.aster.anims.play('idleAsterDown')
  }

  moveLeft = () => {
    if (this.changingScene) return
    if (!this.ellie.body) return

    this.makeFootstepSound()
    this.ellie.x -= 32
    this.aster.x = this.ellie.x + 32 + 10
    this.aster.y = this.ellie.y + 14
    this.aster.anims.play('walkAsterLeft')
    this.ellie.anims.play('walkLeft')
    if (typeof this.onMove === 'function') this.onMove()
  }

  moveLeftDPad = () => {
    if (this.changingScene) return

    this.moveLeft()
    setTimeout(() => {
      if (!this.ellie.body) return
      this.idleLeft()
    }, 100)
  }

  moveRight = () => {
    if (this.changingScene) return
    if (!this.ellie.body) return

    this.makeFootstepSound()
    this.ellie.x += 32
    this.aster.x = this.ellie.x - 32 - 10
    this.aster.y = this.ellie.y + 14
    this.aster.anims.play('walkAsterRight')
    this.ellie.anims.play('walkRight')
    if (typeof this.onMove === 'function') this.onMove()
  }

  moveRightDPad = () => {
    if (this.changingScene) return

    this.moveRight()
    setTimeout(() => {
      if (!this.ellie.body) return
      this.idleRight()
    }, 100)
  }

  moveUp = () => {
    if (this.changingScene) return
    if (!this.ellie.body) return

    this.makeFootstepSound()
    this.ellie.y -= 32
    this.aster.y = this.ellie.y + 32 + 20
    this.aster.x = this.ellie.x
    this.aster.anims.play('walkAsterUp')
    this.ellie.anims.play('walkUp')
    if (typeof this.onMove === 'function') this.onMove()
  }

  moveUpDPad = () => {
    if (this.changingScene) return

    this.moveUp()
    setTimeout(() => {
      if (!this.ellie.body) return
      this.idleUp()
    }, 100)
  }

  moveDown = () => {
    if (this.changingScene) return
    if (!this.ellie.body) return

    this.makeFootstepSound()
    this.ellie.y += 32
    this.aster.y = this.ellie.y - 48
    this.aster.x = this.ellie.x + 2
    this.aster.anims.play('walkAsterDown')
    this.ellie.anims.play('walkDown')
    if (typeof this.onMove === 'function') this.onMove()
  }

  makeFootstepSound() {
    const thisFootstepAt = Date.now()
    console.log(this.lastFootstepAt, thisFootstepAt - 100)
    if (
      this.lastFootstepAt &&
        (this.lastFootstepAt > (thisFootstepAt - 300))
    ) return

    this.lastFootstepAt = thisFootstepAt
    this.footstepSound.play()
  }

  moveDownDPad = () => {
    if (this.changingScene) return

    this.moveDown()
    setTimeout(() => {
      if (!this.ellie.body) return
      this.idleDown()
    }, 100)
  }

  startMonsterMovementInterval() {
    if (window.monsterMovementInterval) clearInterval(window.monsterMovementInterval)

    window.monsterMovementInterval = setInterval(() => {
      if (typeof this.moveMonsters === 'function') this.moveMonsters()
    }, 1000)
  }

  trueOrFalse() {
    return !!this.randomNumber(0, 1)
  }

  randomNumber(min=0, max=1000) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  monitorCollisionsFor(...spriteKeys) {
    spriteKeys.forEach(spriteKey => {
      const sprite = this[spriteKey]
      if (!sprite) throw `Invalid sprite key ${spriteKey}`
      sprite.collisionKey = `${spriteKey}Collision`

      this[sprite.collisionKey] = false
      this.spritesToMonitor.push(sprite)
    })
  }

  isNear(sprite) {
    const NEARNESS_IN_PIXELS = 96
    const compareX = Math.abs(sprite.x - this.ellie.x)
    const compareY = Math.abs(sprite.y - this.ellie.y)
    return compareX < NEARNESS_IN_PIXELS &&
      compareY < NEARNESS_IN_PIXELS
  }
}
