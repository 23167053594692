import React, { useEffect } from 'react'
import Phaser from 'phaser'

import Controls from './controls'
import BedroomAsleepLevel from './levels/bedroom-asleep'
import BedroomAwakeLevel from './levels/bedroom-awake'
import DoorsLevel from './levels/doors'
import PinkLevel from './levels/pink'
import BrownLevel from './levels/brown'
import MagicFloorLevel from './levels/magic-floor'
import AlbumSelectLevel from './levels/album-select'
import MonstersLevel from './levels/monsters'
import TownLevel from './levels/town'
import RecordStoreLevel from './levels/record-store'

export default function Game({ visible }) {
  const width = window.innerWidth
  const height = window.innerHeight

  useEffect(() => {
    new Phaser.Game({
      type: Phaser.AUTO, // Which renderer to use
      parent: 'app', // DOM id
      width,
      height,
      physics: {
        default: 'matter',
        matter: {
          // debug: true,
          gravity: {
              x: 0,
              y: 0
          },
        },
      },
      scene: [
        BedroomAwakeLevel,
        BedroomAsleepLevel,
        DoorsLevel,
        PinkLevel,
        BrownLevel,
        MagicFloorLevel,
        AlbumSelectLevel,
        MonstersLevel,
        TownLevel,
        RecordStoreLevel,
      ],
      render: {
        transparent: 'transparent',
      }
    })
  }, [])

  return <Controls />
}
