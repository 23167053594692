import React from 'react'
import Modal from '../../modal'

export default function RecordStoreModal({ visible }) {
  return (
    <Modal title='Hi, can I help you with something' visible={visible} className='in-game'>
      <ul>
        <li>
          <a href='https://music.apple.com/us/album/metajazz/1510692085'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/album-art/metajazz.jpg)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Buy Metajazz</span>
          </a>
        </li>
        <li>
          <a href='https://music.apple.com/us/album/dear-earth-sorry-ep/1510695382'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/album-art/dear-earth-sorry.jpg)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Buy Dear Earth, Sorry</span>
          </a>
        </li>
        <li>
          <a href='https://music.apple.com/us/album/jack-and-the-box-dreams/1438786050'>
            <div
              style={{
                backgroundImage: 'url(/tilesets/album-art/jack-in-the-box-dreams.jpg)',
                width: 50,
                height: 50,
                backgroundSize: 'contain',
                display: 'inline-block',
              }}
            />
            <span style={{ position: 'relative', top: '-20px', marginLeft: 20 }}>Buy Jack in the Box Dreams</span>
          </a>
        </li>
      </ul>
    </Modal>
  )
}
