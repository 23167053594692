import Level from '../level'

export default class MainLevel extends Level {
  get mapKey() {
    return 'pinkLevel'
  }

  constructor() {
    super('pink')
    this.overPinkDoor = true
  }

  onPreload() {
    this.load.spritesheet('grayghost', 'tilesets/grayghost.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.image('pinkDoor', 'tilesets/door/pink.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.tilemapTiledJSON('pinkLevel', 'tilesets/pink-level.json')
  }

  onCreate() {
    this.ghost = this.matter.add.sprite(100, 120, 'grayghost')
    this.monsters = [this.ghost]
    this.pinkDoor = this.matter.add.sprite(32 * 48, 32, 'pinkDoor', null, { isSensor: true })
    this.pinkDoor.depth = 9

    // order matters here!
    const bgLayer = this.map.createLayer('bg', this.tileset, 0, 0)
    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const middleLayer = this.map.createLayer('middle', this.tileset, 0, 0)
    const middleLayer2 = this.map.createLayer('middle2', this.tileset, 0, 0)
    const topLayer = this.map.createLayer('top', this.tileset, 0, 0)
    const topLayer2 = this.map.createLayer('top2', this.tileset, 0, 0)
    const layers = [
      bgLayer, baseLayer, bottomLayer, middleLayer, middleLayer2, topLayer, topLayer2
    ]

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })

    const startPoint = this.map.heightInPixels - 100
    this.ellie.y = startPoint
  }

  onUpdate (time, delta) {
  }

  onEnter = () => {
    if (this.overPinkDoor)
      this.changeScene('albumSelect')
  }

  onEllieCollisionStart(sprite) {
    if (sprite === this.pinkDoor)
      this.overPinkDoor = true
  }

  onEllieCollisionEnd(sprite) {
    if (sprite === this.pinkDoor)
      this.overPinkDoor = false
  }

  moveMonsters() {
    this.monsters.forEach(monster => {
      if (this.trueOrFalse()) {
        if (this.trueOrFalse()) monster.x += 32
        else monster.x += 32
      }

      if (this.trueOrFalse()) {
        if (this.trueOrFalse()) monster.y += 32
        else monster.y -= 32
      }
    })
  }
}
