import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { next as nextAction } from '../../slices/record-player'

let analyser,
  source,
  ctx,
  animationInterval

export default React.memo(function RecordPlayer() {
  const dispatch = useDispatch()
  const audioRef = useRef()
  const playing = useSelector(state => state.recordPlayer.on)
  const url = useSelector(state => state.recordPlayer.currentTrack?.local)

  ctx = new AudioContext()
  analyser = ctx.createAnalyser()
  animationInterval = ctx.createScriptProcessor(2048, 1, 1)

  useEffect(() => {
    if (!playing) return
    audioRef.current.src = url
    audioRef.current.play()
  }, [audioRef, playing, url])

  useEffect(() => {
    audioRef.current.addEventListener('canplay', () => {
      if (source) return

      // begin: when visualizer is needed
      // source = ctx.createMediaElementSource(audioRef.current)

      // source.connect(analyser)
      // analyser.connect(animationInterval)
      // animationInterval.connect(ctx.destination)
      // analyser.connect(ctx.destination)

      // source.connect(ctx.destination)
      // end: when visualizer is needed
    })

    function dispatchTimeUpdated() {
      // leaving for example purposes, rip out soon
      // const fftData = new Float32Array(analyser.fftSize)
      // analyser.getFloatTimeDomainData(fftData)
      const event = new CustomEvent('non-redux-safe-event::RECORD_PLAYER_TIME_UPDATED', {
        detail: {
          analyser,
          ctx,
        }
      })
      document.body.dispatchEvent(event)
    }

    audioRef.current.addEventListener('play', () => {
      animationInterval.onaudioprocess = dispatchTimeUpdated
    })

    audioRef.current.addEventListener('ended', () => {
      dispatch(nextAction())
    })
  }, [dispatch])

  return (
    <div>
      <audio
        crossOrigin="anonymous"
        playsInline
        autoPlay
        ref={audioRef}
        src={url}
      />
    </div>
  )
})

// not needed anymore?
// function unlockAudioElement(audioElement) {
//   try {
//     audioElement.play()
//     audioElement.pause()
//     audioElement.currentTime = 0
//   } catch {
//     // do nada
//   }
// }
