import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  on: false,
  currentTrack: null,
  queue: [],
}

const recordPlayerSlice = createSlice({
  name: 'recordPlayer',
  initialState,
  reducers: {
    play(state, action) {
      if (action.payload.queue) {
        state.queue = [...action.payload.queue]
        state.currentTrack = state.queue.shift()
      }

      state.on = true
    },

    next(state, action) {
      state.currentTrack = state.queue.shift() || null
    },

    skipTo(state, action) {
      const track = action.payload
      const beginning = []
      const end = []
      let trackFound = false

      state.queue.forEach(t => {
        if (t.title === track.title) trackFound = true

        if (trackFound) beginning.push(t)
        else end.push(t)
      })

      const queue = beginning.concat(end).concat([state.currentTrack])
      const currentTrack = queue.shift()

      state.currentTrack = currentTrack
      state.queue = queue
    },
  },
})

export const { next, play, skipTo } = recordPlayerSlice.actions
export default recordPlayerSlice.reducer
