import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { mobileCutoff } from '../config'
const controlsHeight = 150

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0))
}
const touchDevice = isTouchDevice()

export default function Controls() {
  const { windowWidth } = useSelector(state => state.app)
  let movementInterval = null

  const moveWithInterval = movementFunction => {
    const event = new CustomEvent(movementFunction)
    document.body.dispatchEvent(event)

    if (movementInterval) clearInterval(movementInterval)

    movementInterval = setInterval(() => {
      const event = new CustomEvent(movementFunction)
      document.body.dispatchEvent(event)
    }, 100)
  }

  useEffect(() => {
    const clearMovementInterval = () => {
      if (!movementInterval) return
      clearInterval(movementInterval)
    }
    document.addEventListener('sceneChange', clearMovementInterval)

    return () => {
      document.removeEventListener('sceneChange', clearMovementInterval)
    }
  }, [])

  return (
    <div>
      {
        windowWidth <= mobileCutoff &&
        <div className='controls' style={{ height: controlsHeight }}>
          <div className='buttons'>
            <div className='d-pad'>
              <button
                onContextMenu={ e => {
                  e.preventDefault()
                }}
                onMouseDown={ touchDevice ? null : () => moveWithInterval('moveLeft') }
                onMouseUp={ touchDevice ? null : () => clearInterval(movementInterval) }
                onTouchStart={ touchDevice ? () => moveWithInterval('moveLeft') : null }
                onTouchEnd={ touchDevice ? () => clearInterval(movementInterval) : null }
                className='left'
              ></button>

              <button
                onContextMenu={ e => {
                  e.preventDefault()
                }}
                onMouseDown={ touchDevice ? null : () => moveWithInterval('moveUp') }
                onMouseUp={ touchDevice ? null : () => clearInterval(movementInterval) }
                onTouchStart={ touchDevice ? () => moveWithInterval('moveUp') : null }
                onTouchEnd={ touchDevice ? () => clearInterval(movementInterval) : null }
                className='up'
              ></button>

              <div className='filler'></div>
              <button
                onContextMenu={ e => {
                  e.preventDefault()
                }}
                onMouseDown={ touchDevice ? null : () => moveWithInterval('moveDown') }
                onMouseUp={ touchDevice ? null : () => clearInterval(movementInterval) }
                onTouchStart={ touchDevice ? () => moveWithInterval('moveDown') : null }
                onTouchEnd={ touchDevice ? () => clearInterval(movementInterval) : null }
                className='down'
              ></button>

              <button
                onContextMenu={ e => {
                  e.preventDefault()
                }}
                onMouseDown={ touchDevice ? null : () => moveWithInterval('moveRight') }
                onMouseUp={ touchDevice ? null : () => clearInterval(movementInterval) }
                onTouchStart={ touchDevice ? () => moveWithInterval('moveRight') : null }
                onTouchEnd={ touchDevice ? () => clearInterval(movementInterval) : null }
                className='right'
              ></button>
            </div>
            <div className='action'>
              <button
                onContextMenu={ e => {
                  e.preventDefault()
                }}
                onMouseDown={ touchDevice ? null : () => {
                  const event = new CustomEvent('enter')
                  document.body.dispatchEvent(event)
                }}
                onClick={ () => {
                  const event = new CustomEvent('enter')
                  document.body.dispatchEvent(event)
                }}
                className='enter'
              ></button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
