import React from 'react'
import Typewriter from 'react-typewriter'

export default function ListlessAndWet({ onComplete }={}) {
  return (
    <div className='poem'>
      <Typewriter
        typing={1}
        minDelay={200}
        onTypingEnd={onComplete}
      >
        <p>listless and wet</p>
        <p>trodden in gravy socks</p>
        <p>soaked, filthy, dancing</p>
        <p>casting frantic glares</p>
        <p>spooning delightfully to the moon</p>
        <p>to sip every ounce of sweetness</p>
        <p>eyes bulged, mouth dripping sandwich bags</p>
        <p>slugged beneath the carls jr lights</p>
        <p>casting frantic glares</p>
        <p>(dr hilarious is a dream)</p>
        <p>who i am a fog</p>
        <p>lost in neon florescence</p>
      </Typewriter>
    </div>
  )
}
