import React from 'react'
import Typewriter from 'react-typewriter'

export default function ListlessAndWet({ onComplete }={}) {
  return (
    <div className='poem'>
      <Typewriter
        typing={1}
        minDelay={200}
        onTypingEnd={onComplete}
      >
        <p>what piety</p>
        <p>what sorrow</p>
        <p>that your legs should reach so far through the ground</p>
        <p>they drive through the heart of the planet</p>
        <p>jutting violently out her back</p>
        <p>they pierce the moon</p>
        <p>they continue</p>
        <p>they continue</p>
        <p>in 400ft</p>
        <p>they continue</p>
      </Typewriter>
    </div>
  )
}
