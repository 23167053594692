import React from 'react'
import Typewriter from 'react-typewriter'

export default function ListlessAndWet({ onComplete }={}) {
  return (
    <div className='poem'>
      <Typewriter
        typing={1}
        minDelay={200}
        onTypingEnd={onComplete}
      >
        <p>Narcissus made nice with the red chips of paint</p>
        <p>Knotted circularly across the ceiling</p>
        <p>She thought of other times</p>
        <p>Shaking gladly, patiently</p>
        <p>In the corner of her room, cowering</p>
        <br/><br/>
        <p>Her eyes strobed</p>
        <p>Black, white, black, white, some colors in between</p>
        <p>An agent or two just outside</p>
        <p>Dr Hilarious shook his tooth</p>
        <p>The whole world came crumbling down</p>
        <p>There she shook in a pool of tears</p>
        <p>She mistook as her own</p>
      </Typewriter>
    </div>
  )
}
