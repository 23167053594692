import Level from '../level'

export default class MagicFloorLevel extends Level {
  get mapKey() {
    return 'magicFloorLevel'
  }

  constructor() {
    super('magicFloor')
    this.magicTiles = []
  }

  onPreload() {
    this.load.spritesheet('magicFloor', 'tilesets/magic-floor.png', this.frameDimensions)
    this.load.image('pinkDoor', 'tilesets/door/pink.png', this.frameDimensions)
    this.load.tilemapTiledJSON('magicFloorLevel', 'tilesets/magic-floor.json')
  }

  onCreate() {
    this.pinkDoor = this.matter.add.sprite(
      (32 * 33) + 16,
      32,
      'pinkDoor',
      null,
      { isSensor: true }
    )
    this.pinkDoor.displayWidth = 32 * 9
    this.pinkDoor.depth = -1

    // order matters here!
    const bgLayer = this.map.createLayer('bg', this.tileset, 0, 0)
    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const layers = [
      bgLayer, baseLayer, bottomLayer,
    ]

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })

    const startPoint = this.map.heightInPixels - 96
    this.ellie.y = startPoint
  }

  onUpdate (time, delta) {
  }

  onMove() {
    const tileDelay = 1000
    this.magicTiles.forEach(tile => {
      this.add.tween({
        targets: [tile],
        ease: 'Sine.easeInOut',
        duration: tileDelay,
        alpha: {
          getStart: () => 1,
          getEnd: () => 0
        },
        onComplete: () => {
          tile.destroy(true)
        },
      })
    })

    const tile = this.matter.add.sprite(
      (32 * (this.ellie.x / 32)) - 12,
      (32 * (this.ellie.y / 32)) + 16,
      'magicFloor',
      null,
      { isSensor: true }
    )
    tile.depth = -1

    this.add.tween({
      targets: [tile],
      ease: 'Sine.easeInOut',
      duration: tileDelay,
      alpha: {
        getStart: () => 0,
        getEnd: () => 1,
      },
      onComplete: () => {
      },
    })
    this.magicTiles.push(tile)
  }

  onEnter = () => {
  }

  onEllieCollisionStart(sprite) {
    if (sprite === this.pinkDoor)
      this.changeScene('albumSelect')
  }

  onEllieCollisionEnd(sprite) {
  }
}
