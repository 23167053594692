const metajazz = {
  title: 'Metajazz',
  artworkUrl: '/img/album-art/metajazz.jpg',
  tracks: [
    {
      title: 'Birthed Like The Fool',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/Birthed%20Like%20The%20Fool.mp3',
      local: '/audio/metajazz/birthed-like-the-fool.mp3',
    },
    {
      title: 'Before Life',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/Before%20Life.mp3',
      local: '/audio/metajazz/before-life.mp3',
    },
    {
      title: 'The Cool Strung About Their Hilt',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/The%20Cool%20Strung%20About%20Their%20Hilt.mp3',
      local: '/audio/metajazz/the-cool-strung-about-their-hilt.mp3',
    },
    {
      title: 'Two Swords Of Stain Laid Trapeze',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/Two%20Swords%20Of%20Stain%20Laid%20Trapeze.mp3',
      local: '/audio/metajazz/two-swords-of-stain-laid-trapeze.mp3',
    },
    {
      title: 'Solemn Frost Dreams Lay Undone',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/Solemn%20Frost%20Dreams%20Lay%20Undone.mp3',
      local: '/audio/metajazz/solemn-frost-dreams-lay-undone.mp3',
    },
    {
      title: "Pining At Heaven's Gate",
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/Pining%20At%20Heaven%27s%20Gate.mp3',
      local: '/audio/metajazz/pining-at-heavens-gate.mp3',
    },
    {
      title: "A Song Bird Crow Hangs Like Jupiter's Moonbeam",
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/A%20Song%20Bird%20Crow%20Hangs%20Like%20Jupiter%27s%20Moonbeam.mp3',
      local: '/audio/metajazz/a-songbird-crow-hangs-like-jupiters-moonbeam.mp3',
    },
    {
      title: "Should Dreams Pop Out Of Soda Machines",
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/metajazz/Should%20Dreams%20Pop%20Out%20Of%20Soda%20Machines.mp3',
      local: '/audio/metajazz/should-dreams-pop-out-of-soda-machines.mp3',
    },
  ]
}

export default metajazz
