import React from 'react'

export default function Stars({ visible }={ visible: true }) {
  if (!visible) return null

  return (
    <div>
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
    </div>
  )
}
