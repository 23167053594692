import { store } from '../../store'
import { setCurrentPoem as setCurrentPoemAction } from '../../../slices/app'
import Level from '../level'

export default class MonstersLevel extends Level {
  get mapKey() {
    return 'monstersLevel'
  }

  constructor() {
    super('monsters')
    this.crazyMachines = []
    this.monkeyHyrogliphs = []
  }

  onPreload() {
    this.load.spritesheet('crazyMachine', 'tilesets/crazy-machine.png', this.frameDimensions)
    this.load.spritesheet('pinkDoor', 'tilesets/door/pink.png', this.frameDimensions)
    this.load.spritesheet('greenDiary', 'tilesets/diary/green.png', this.frameDimensions)
    this.load.spritesheet('blueDiary', 'tilesets/diary/blue.png', this.frameDimensions)
    this.load.spritesheet('monkeyHyrogliph', 'tilesets/monkey-hyrogliph.png', { frameWidth: 256, frameHeight: 256 })
    this.load.tilemapTiledJSON('monstersLevel', 'tilesets/monsters.json')
  }

  onCreate() {
    this.ellie.x = this.map.widthInPixels / 2
    this.ellie.y = this.map.heightInPixels / 2

    const numCrazyMachines = 10
    for (let i = 0; i <= numCrazyMachines; i++) {
      const crazyMachine = this.matter.add.sprite(
        32 * this.randomNumber(0, (2000 / 32)),
        32 * this.randomNumber(0, (2000 / 32)),
        'crazyMachine',
        null,
        { isSensor: true }
      )
      this.crazyMachines.push(crazyMachine)
    }

    const hyrogliph1 = this.matter.add.sprite(
      this.map.widthInPixels / 2, 192, 'monkeyHyrogliph', null, { isSensor: true }
    )
    const hyrogliph2 = this.matter.add.sprite(
      this.map.widthInPixels / 2, this.map.heightInPixels - 192, 'monkeyHyrogliph', null, { isSensor: true }
    )
    const hyrogliph3 = this.matter.add.sprite(
      this.map.widthInPixels - 192, this.map.heightInPixels / 2, 'monkeyHyrogliph', null, { isSensor: true }
    )
    const hyrogliph4 = this.matter.add.sprite(
      192, this.map.heightInPixels / 2, 'monkeyHyrogliph', null, { isSensor: true }
    )
    this.monkeyHyrogliphs.push(hyrogliph1)
    this.monkeyHyrogliphs.push(hyrogliph2)
    this.monkeyHyrogliphs.push(hyrogliph3)
    this.monkeyHyrogliphs.push(hyrogliph4)

    this.pinkDoor = this.matter.add.sprite(
      (32 * 47) + 16, (32 * 174) + 16, 'pinkDoor', null, { isSensor: true }
    )

    this.whatPietyDiary = this.matter.add.sprite(
      (32 * 60) + 16, (32 * 27) + 16, 'greenDiary', null, { isSensor: true }
    )
    this.listlessAndWetDiary = this.matter.add.sprite(
      (32 * 139) + 16, (32 * 27) + 16, 'blueDiary', null, { isSensor: true }
    )
    this.narcissusDiary = this.matter.add.sprite(
      (32 * 24) + 16, (32 * 120) + 16, 'greenDiary', null, { isSensor: true }
    )
    this.scowlsAtANightingaleDiary = this.matter.add.sprite(
      (32 * 195) + 16, (32 * 3) + 16, 'blueDiary', null, { isSensor: true }
    )
    this.somethingSolicitsMeDiary = this.matter.add.sprite(
      (32 * 2) + 16, (32 * 196) + 16, 'greenDiary', null, { isSensor: true }
    )

    this.monitorCollisionsFor(
      'pinkDoor',
      'whatPietyDiary',
      'listlessAndWetDiary',
      'narcissusDiary',
      'scowlsAtANightingaleDiary',
      'somethingSolicitsMeDiary',
    )

    // order matters here!
    const bgLayer = this.map.createLayer('bg', this.tileset, 0, 0)
    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const layers = [
      bgLayer, baseLayer, bottomLayer,
    ]

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })
  }

  onUpdate (time, delta) {
  }

  moveMonsters() {
    this.crazyMachines.forEach(machine => {
      if (!machine.body) return
      machine.setFrame(this.randomNumber(0, 1))

      if (this.trueOrFalse()) {
        if (this.trueOrFalse()) machine.x += 32
        else machine.x += 32
      }

      if (this.trueOrFalse()) {
        if (this.trueOrFalse()) machine.y += 32
        else machine.y -= 32
      }
    })

    this.monkeyHyrogliphs.forEach(hyrogliph => {
      hyrogliph.setFrame(this.randomNumber(0, 1))
    })
  }

  onEnter = () => {
    if (this.pinkDoorCollision)
      this.changeScene('doors')

    else if (this.listlessAndWetDiaryCollision)
      store.dispatch(setCurrentPoemAction('listlessandwet'))

    else if (this.whatPietyDiaryCollision)
      store.dispatch(setCurrentPoemAction('whatpiety'))

    else if (this.narcissusDiaryCollision)
      store.dispatch(setCurrentPoemAction('narcissus'))

    else if (this.scowlsAtANightingaleDiaryCollision)
      store.dispatch(setCurrentPoemAction('nightingale'))

    else if (this.somethingSolicitsMeDiaryCollision)
      store.dispatch(setCurrentPoemAction('somethingsolicitsme'))
  }
}
