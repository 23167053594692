import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { skipTo as skipToAction } from '../../slices/record-player'
import Modal from './modal'

export default function RecordQueueModal({ visible }) {
  const dispatch = useDispatch()
  const queue = useSelector(state => state.recordPlayer.queue)
  const currentTrack = useSelector(state => state.recordPlayer.currentTrack)

  return (
    <Modal title='' visible={visible}>
      <h3>{currentTrack?.title}</h3>
      <ul>
        {
          queue.map(track =>
            <li
              key={track.title}
              onClick={() => {
                dispatch(skipToAction(track))
              }}
            >
              <button>
                {track.title}
              </button>
            </li>
          )
        }
      </ul>
    </Modal>
  )
}
