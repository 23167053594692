const jackInTheBoxDreams = {
  title: 'Jack in the Box Dreams',
  artworkUrl: '/img/album-art/jack-in-the-box-dreams.jpg',
  tracks: [
    {
      title: 'Brazen Snow Leopard',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Brazen%20Snow%20Leaopard.mp3',
      local: '/audio/jack-in-the-box-dreams/brazen-snow-leopard.mp3',
    },
    {
      title: 'Tiptoeing Madly Down South Grand',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Tiptoeing%20Madly%20Down%20South%20Grand.mp3',
      local: '/audio/jack-in-the-box-dreams/tiptoeing-madly-down-south-grand.mp3',
    },
    {
      title: 'Jack In The Box Dreams',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Jack%20In%20The%20Box%20Dreams.mp3',
      local: '/audio/jack-in-the-box-dreams/jack-in-the-box-dreams.mp3',
    },
    {
      title: 'Crumbled Onion Rings',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Crumbled%20Onion%20Rings.mp3',
      local: '/audio/jack-in-the-box-dreams/crumbled-onion-rings.mp3',
    },
    {
      title: 'Garbage Tumbleweeds',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Garbage%20Tumbleweeds.mp3',
      local: '/audio/jack-in-the-box-dreams/garbage-tumbleweeds.mp3',
    },
    {
      title: 'Popov To Kill The Night Shade',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Popov%20To%20Kill%20The%20Night%20Shade.mp3',
      local: '/audio/jack-in-the-box-dreams/popov-to-kill-the-nightshade.mp3',
    },
    {
      title: 'Sweet As A Setting Sun',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Sweet%20As%20A%20Setting%20Sun.mp3',
      local: '/audio/jack-in-the-box-dreams/sweet-as-a-setting-sun.mp3',
    },
    {
      title: 'Glittering Bright Vivaldi',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Glittering%20Bright%20Vivaldi.mp3',
      local: '/audio/jack-in-the-box-dreams/glittering-bright-vivaldi.mp3',
    },
    {
      title: 'To Watch You Stream Your Arms',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/To%20Watch%20You%20Stream%20Your%20Arms.mp3',
      local: '/audio/jack-in-the-box-dreams/to-watch-you-stream-your-arms.mp3',
    },
    {
      title: 'Furiously Above Your Head',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Furiously%20Above%20Your%20Head.mp3',
      local: '/audio/jack-in-the-box-dreams/furiously-above-your-head.mp3',
    },
    {
      title: 'Symphonies Of A Bad Goodbye',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/Symphonies%20Of%20A%20Bad%20Goodbye.mp3',
      local: '/audio/jack-in-the-box-dreams/symphonies-of-a-bad-goodbye.mp3',
    },
    {
      title: 'To Lend The Night Your Struggling Arm',
      url: 'https://avocadojesus.sfo3.digitaloceanspaces.com/jack-in-the-box-dreams/To%20Lend%20The%20Night%20Your%20Struggling%20Arm.mp3',
      local: '/audio/jack-in-the-box-dreams/to-lend-the-night-your-struggling-arm.mp3',
    },
  ]
}

export default jackInTheBoxDreams
