import { configureStore } from '@reduxjs/toolkit'
import recordPlayer from '../slices/record-player'
import app from '../slices/app'

export const store = configureStore({
  reducer: {
    app,
    recordPlayer,
  },
})
