import { store } from '../../store'
import { play as playAction } from '../../../slices/record-player'
import Level from '../level'
import dearEarthSorryAlbum from '../../records/dear-earth-sorry'

export default class BedroomAsleepLevel extends Level {
  get mapKey() {
    return 'bedroomAsleepLevel'
  }

  get useStandardHeight() {
    return false
  }

  constructor() {
    super('bedroomAsleep')
  }

  onPreload() {
    this.load.spritesheet('grayghost', 'tilesets/grayghost.png', this.frameDimensions)
    this.load.image('dearEarthSorry', 'tilesets/album-art/dear-earth-sorry.jpg', this.frameDimensions)
    this.load.image('bed', 'tilesets/bed.png', { frameWidth: 64, frameHeight: 64 })
    this.load.tilemapTiledJSON('bedroomAsleepLevel', 'tilesets/bedroom-asleep-level.json')
  }

  onCreate() {
    this.ellie.x = 32 * 4
    this.ellie.y = 32 * 4

    this.aster.x = 32 * 3
    this.aster.y = 32 * 4

    this.bed = this.matter.add.sprite(32, 32 * 6, 'bed')
    this.bed.depth = 8
    this.bed.angle += 180

    this.dearEarthSorry = this.matter.add.sprite(
      (32 * 13) + 16,
      64,
      'dearEarthSorry',
      null,
      { isSensor: true }
    )
    this.dearEarthSorry.depth = 8
    this.dearEarthSorry.displayWidth = 24
    this.dearEarthSorry.displayHeight = 24

    this.monitorCollisionsFor('dearEarthSorry', 'bed')

    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const middleLayer = this.map.createLayer('middle', this.tileset, 0, 0)
    const layers = [
      baseLayer, bottomLayer, middleLayer,
    ]

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })

    this.scale.resize(Math.min(32 * 15, window.innerWidth), 32 * 7)
  }

  onUpdate (time, delta) {
  }

  onEnter = () => {
    if (this.bedCollision)
      this.changeScene('bedroomAwake')

    else if (this.dearEarthSorryCollision) {
      this.changeScene('doors')
      setTimeout(() => {
        store.dispatch(playAction({ queue: dearEarthSorryAlbum.tracks }))
      })
    }
  }
}
