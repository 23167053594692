import Level from '../level'

export default class RecordStoreLevel extends Level {
  get mapKey() {
    return 'recordStoreLevel'
  }

  constructor() {
    super('recordStore')
  }

  get useStandardHeight() {
    return false
  }

  onPreload() {
    this.load.spritesheet('grayghost', 'tilesets/grayghost.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.spritesheet('cameraghost', 'tilesets/cameraghost.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.spritesheet('computer', 'tilesets/computer.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.image('pinkDoor', 'tilesets/door/pink.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.image('brownDoor', 'tilesets/door/brown.png', {
      frameWidth: 32 * 5,
      frameHeight: 64,
    })
    this.load.tilemapTiledJSON('recordStoreLevel', 'tilesets/record-store-level.json')
  }

  onCreate() {
    this.ellie.x = 32 * 5
    this.ellie.y = window.innerHeight

    this.cameraghost = this.matter.add.sprite(32 * 15, 32 * 7, 'cameraghost')
    this.cameraghost.depth = 9

    this.computer = this.matter.add.sprite(32 * 24, 32 * 9, 'computer')
    this.computer.depth = 9

    this.pinkDoor = this.matter.add.sprite(16, this.map.heightInPixels - 32, 'brownDoor', null, { isSensor: true })
    this.pinkDoor.depth = 9

    this.buildingDoor = this.matter.add.sprite((32 * 10), 32 * 3, 'pinkDoor')
    this.buildingDoor.displayWidth = 32 * 5

    // order matters here!
    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const middleLayer = this.map.createLayer('middle', this.tileset, 0, 0)
    const layers = [
      baseLayer, bottomLayer, middleLayer,
    ]

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })

    const startPoint = this.map.heightInPixels - 100
    this.ellie.y = startPoint

    this.monitorCollisionsFor(
      'buildingDoor',
      'pinkDoor',
    )

    this.scale.resize(Math.min(32 * 15, window.innerWidth), 32 * 10)
  }

  onUpdate (time, delta) {
  }

  onEnter = () => {
    if (this.isNear(this.buildingDoor))
      this.showCustomModal('recordStore:buyAlbum')

    else if (this.isNear(this.cameraghost))
      this.showCustomModal('viewMedia')

    else if (this.isNear(this.computer))
      this.showModal(
        'Wanna get in touch with Avocado Jesus?',
        'Go ahead and reach out to him at fred@avocadojesus.com!',
      )
  }

  onEllieCollisionStart(sprite) {
    if (sprite === this.pinkDoor) {
      // easiest way to do this, sorry
      window.__returningFromRecordStore = true
      this.changeScene('town')
    }
  }
}
