import React from 'react'
import Typewriter from 'react-typewriter'

export default function Astros({ onComplete }={}) {
  return (
    <div className='poem'>
      <Typewriter
        typing={1}
        minDelay={200}
        onTypingEnd={onComplete}
      >
        <p>I love the Astros I love love love the Astros they are the best a@ baseball!</p>
        <p>I am sure that I love them.</p>
        <p>When I consider all other teams, for me I am for them in love.</p>
        <p>I love them so much I can see myself with them forever and I love sports.</p>
        <p>My favorite sport @baseball triggers me to leave events behind and as I move forward I will continue to love baseball.</p>
        <p>This post is to talk about how I love the astros baseball team see fox sports and enjoy Astros.</p>
      </Typewriter>
    </div>
  )
}
