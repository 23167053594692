import Level from '../level'

export default class BrownLevel extends Level {
  get mapKey() {
    return 'brownLevel'
  }

  constructor() {
    super('brown')
    this.overBrownDoor = true
  }

  onPreload() {
    this.load.spritesheet('grayghost', 'tilesets/grayghost.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.image('brownDoor', 'tilesets/door/brown.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.tilemapTiledJSON('brownLevel', 'tilesets/brown-level.json')
  }

  onCreate() {
    this.ghost = this.matter.add.sprite(100, 120, 'grayghost')
    this.monsters = [this.ghost]
    this.brownDoor = this.matter.add.sprite(32 * 48, 96, 'brownDoor', null, { isSensor: true })
    this.brownDoor.depth = 9

    // order matters here!
    const bgLayer = this.map.createLayer('bg', this.tileset, 0, 0)
    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const layers = [
      bgLayer, baseLayer, bottomLayer,
    ]

    this.monitorCollisionsFor('brownDoor')

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })

    const startPoint = this.map.heightInPixels - 100
    this.ellie.y = startPoint
  }

  onUpdate (time, delta) {
  }

  onEnter = () => {
    if (this.brownDoorCollision)
      this.changeScene('albumSelect')
  }

  moveMonsters() {
    this.monsters.forEach(monster => {
      if (this.trueOrFalse()) {
        if (this.trueOrFalse()) monster.x += 32
        else monster.x += 32
      }

      if (this.trueOrFalse()) {
        if (this.trueOrFalse()) monster.y += 32
        else monster.y -= 32
      }
    })
  }
}
