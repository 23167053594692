import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BrowserRouter,
} from "react-router-dom"

import {
  setWindowSize as setWindowSizeAction,
  setCurrentPoem as setCurrentPoemAction,
} from './slices/app'
import Game from './app/game'
import RecordPlayer from './app/record-player'
import Poems from './app/poems'
import BallsVisualizer from './app/visualizers/balls'
import Stars from './app/ui/stars'
import AstrosPoem from './app/poems/astros'
import NarcissusPoem from './app/poems/narcissus'
import PortraitOfLRonHubbardPoem from './app/poems/portrait-of-l-ron-hubbard'
import ListlessAndWetPoem from './app/poems/listless-and-wet'
import ScowlsAtANightingalePoem from './app/poems/scowls-at-a-nightingale'
import SomethingSolicitsMePoem from './app/poems/something-solicits-me'
import WhatPietyPoem from './app/poems/what-piety'

import RecordQueueModal from './app/modals/record-queue-modal'
import RecordStoreBuyAlbumModal from './app/modals/levels/record-store/buy-album-modal'
import ViewMediaModal from './app/modals/levels/record-store/view-media'
import InGameModal from './app/modals/in-game-modal'

import './style/index'

function App() {
  const dispatch = useDispatch()
  const showPoems = useSelector(state => state.app.showPoems)
  const currentPoem = useSelector(state => state.app.currentPoem)
  const bgClass = useSelector(state => state.app.bgClass)
  const visualizer = useSelector(state => state.app.visualizer)
  const modal = useSelector(state => state.app.modal)
  const modalClass = modal ? 'modal-open' : 'modal-closed'

  useEffect(() => {
    function handleResize() {
      dispatch(setWindowSizeAction())
    }

    window.addEventListener('resize', handleResize)
  }, [])

  let Visualizer
  switch(visualizer) {
  case 'balls':
    Visualizer = BallsVisualizer
    break

  default:
    Visualizer = null
  }

  let Poem
  switch(currentPoem) {
  case 'astros':
    Poem = AstrosPoem
    break

  case 'narcissus':
    Poem = NarcissusPoem
    break

  case 'lronhubbard':
    Poem = PortraitOfLRonHubbardPoem
    break

  case 'listlessandwet':
    Poem = ListlessAndWetPoem
    break

  case 'nightingale':
    Poem = ScowlsAtANightingalePoem
    break

  case 'somethingsolicitsme':
    Poem = SomethingSolicitsMePoem
    break

  case 'whatpiety':
    Poem = WhatPietyPoem
    break

  default:
    Poem = null
  }

  return (
    <div id='app' className={`app backdrop ${bgClass} ${modalClass}`}>
      <BrowserRouter>
        <div className='app-contents'>
          <Game visible={!modal} />
          <RecordPlayer />
        </div>
        <RecordQueueModal visible={modal === 'record_queue'} />
        <RecordStoreBuyAlbumModal visible={modal === 'recordStore:buyAlbum'} />
        <InGameModal visible={modal === 'inGame'} />
        <ViewMediaModal visible={modal==='viewMedia'} />
        {
          Poem &&
            <div id='poems'>
              <Poem
                onComplete={ () =>
                  dispatch(setCurrentPoemAction(null))
                }
              />
            </div>
        }
        { Visualizer && <Visualizer /> }
        { showPoems && <Poems /> }
      </BrowserRouter>
      <Stars visible={['space', 'peptopink'].includes(bgClass)}/>
    </div>
  )
}

export default App
