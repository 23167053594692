import React from 'react'
import { useSelector } from 'react-redux'

import Modal from './modal'

export default function InGameModal({ visible }) {
  const title = useSelector(state => state.app.modalTitle)
  const body = useSelector(state => state.app.modalBody)

  return (
    <Modal title={title} visible={visible} className='in-game'>
      {body}
    </Modal>
  )
}
