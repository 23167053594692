export default function configureAnimations(phaser) {
  phaser.anims.create({
    key: 'idleLeft',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 9, end: 9 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'idleRight',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 3, end: 3 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'idleUp',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 0, end: 0 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'idleDown',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 6, end: 6 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkLeft',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 10, end: 12 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkRight',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 3, end: 5 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkUp',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 0, end: 2 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkDown',
    frames: phaser.anims.generateFrameNumbers('ellie', { start: 6, end: 8 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'idleAsterLeft',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 3, end: 3 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'idleAsterRight',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 6, end: 6 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'idleAsterUp',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 10, end: 10 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'idleAsterDown',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 0, end: 0 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkAsterLeft',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 3, end: 5 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkAsterRight',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 6, end: 8 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkAsterUp',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 10, end: 12 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'walkAsterDown',
    frames: phaser.anims.generateFrameNumbers('aster', { start: 0, end: 2 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'stepOnMagicFloorTile',
    frames: phaser.anims.generateFrameNumbers('magicFloor', { start: 1, end: 1 }),
    frameRate: 10,
    repeat: -1
  })

  phaser.anims.create({
    key: 'stepOffMagicFloorTile',
    frames: phaser.anims.generateFrameNumbers('magicFloor', { start: 0, end: 0 }),
    frameRate: 10,
    repeat: -1
  })
}
