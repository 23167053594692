import Level from '../level'

export default class TownLevel extends Level {
  get mapKey() {
    return 'townLevel'
  }

  constructor() {
    super('town')
  }

  onPreload() {
    this.load.spritesheet('grayghost', 'tilesets/grayghost.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.image('pinkDoor', 'tilesets/door/pink.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.image('brownDoor', 'tilesets/door/brown.png', {
      frameWidth: 32 * 5,
      frameHeight: 64,
    })
    this.load.tilemapTiledJSON('townLevel', 'tilesets/town-level.json')
  }

  onCreate() {
    if (window.__returningFromRecordStore) {
      this.ellie.x = 32 * 31
      this.ellie.y = 32 * 12

    } else {
      this.ellie.x = 32 * 5
      this.ellie.y = window.innerHeight
      this.ellie.y = this.map.heightInPixels - 100
    }

    this.ghost1 = this.matter.add.sprite(32 * 10, 32 * 32, 'grayghost')
    this.ghost1.depth = 9

    this.ghost2 = this.matter.add.sprite(32 * 14, 32 * 30, 'grayghost')
    this.ghost2.depth = 9

    this.ghost3 = this.matter.add.sprite(32 * 48, 32 * 23, 'grayghost')
    this.ghost3.depth = 9

    this.ghost4 = this.matter.add.sprite(32 * 46, 32 * 20, 'grayghost')
    this.ghost4.depth = 9

    this.pinkDoor = this.matter.add.sprite(32 * 2, this.map.heightInPixels - (32 * 3), 'pinkDoor', null, { isSensor: true })
    this.pinkDoor.depth = 9

    this.buildingDoor = this.matter.add.sprite((32 * 29) + 16, 32 * 9, 'brownDoor')
    this.buildingDoor.displayWidth = 32 * 5

    // order matters here!
    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const middleLayer = this.map.createLayer('middle', this.tileset, 0, 0)
    const layers = [
      baseLayer, bottomLayer, middleLayer,
    ]

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })

    this.monitorCollisionsFor(
      'buildingDoor',
      'pinkDoor',
      'ghost1',
      'ghost2',
      'ghost3',
      'ghost4',
    )
  }

  onUpdate (time, delta) {
  }

  onEnter = () => {
    if (this.pinkDoorCollision)
      this.changeScene('bedroomAwake')

    else if (this.isNear(this.ghost1))
      this.showModal('', 'Have you heard the latest avojeez0 record? I love it like a good sandwich!')

    else if (this.isNear(this.ghost2))
      this.showModal('', 'Avojeez0\'s latest EP "Dear Earth, Sorry" is something to be remembered!')

    else if (this.isNear(this.ghost3))
      this.showModal('', 'Did you know that you can actually purchase all of Avocado Jesus\'s records in the town record store?')

    else if (this.isNear(this.ghost4))
      this.showModal('', 'I just bought metajazz, and i can\'t get enough! What will he come up with next???')
  }

  onEllieCollisionStart(sprite) {
    if (sprite === this.buildingDoor)
      this.changeScene('recordStore')
  }
}
