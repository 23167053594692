import Phaser from 'phaser'
import { store } from '../../store'
import { play as playAction } from '../../../slices/record-player'
import Level from '../level'
import dearEarthSorryAlbum from '../../records/dear-earth-sorry'
import jackInTheBoxDreamsAlbum from '../../records/jack-in-the-box-dreams'
import metajazzAlbum from '../../records/metajazz'

export default class AlbumSelectLevel extends Level {
  get mapKey() {
    return 'albumSelectLevel'
  }

  constructor() {
    super('albumSelect')
    this.overBrownDoor = true
  }

  onPreload() {
    this.load.image('dearEarthSorry', 'tilesets/album-art/dear-earth-sorry.jpg', this.frameDimensions)
    this.load.image('metajazz', 'tilesets/album-art/metajazz.jpg', this.frameDimensions)
    this.load.image('jackInTheBoxDreams', 'tilesets/album-art/jack-in-the-box-dreams.jpg', this.frameDimensions)
    this.load.tilemapTiledJSON('albumSelectLevel', 'tilesets/album-select.json')
  }

  onCreate() {
    this.ellie.x = 32 * 19
    this.ellie.y = window.innerHeight

    this.dearEarthSorry = this.matter.add.sprite(
      32 * 8,
      32,
      'dearEarthSorry',
      null,
      { isSensor: true }
    )
    this.dearEarthSorry.displayWidth = 64
    this.dearEarthSorry.displayHeight = 64
    this.dearEarthSorry.depth = 9

    this.metajazz = this.matter.add.sprite(
      32 * 20,
      32,
      'metajazz',
      null,
      { isSensor: true }
    )
    this.metajazz.displayWidth = 64
    this.metajazz.displayHeight = 64
    this.metajazz.depth = 9

    this.jackInTheBoxDreams = this.matter.add.sprite(
      32 * 32,
      32,
      'jackInTheBoxDreams',
      null,
      { isSensor: true }
    )
    this.jackInTheBoxDreams.displayWidth = 64
    this.jackInTheBoxDreams.displayHeight = 64
    this.jackInTheBoxDreams.depth = 9

    // order matters here!
    const bgLayer = this.map.createLayer('bg', this.tileset, 0, 0)
    const baseLayer = this.map.createLayer('base', this.tileset, 0, 0)
    const bottomLayer = this.map.createLayer('bottom', this.tileset, 0, 0)
    const layers = [
      bgLayer, baseLayer, bottomLayer,
    ]

    layers.forEach(layer => {
      layer.setCollisionByProperty({ collisions: true })
      this.matter.world.convertTilemapLayer(layer)
    })

    const startPoint = this.map.heightInPixels - 100
    this.ellie.y = startPoint

    this.monitorCollisionsFor(
      'dearEarthSorry',
      'metajazz',
      'jackInTheBoxDreams',
    )
  }

  onUpdate (time, delta) {
  }

  onEnter = () => {
    if (this.dearEarthSorryCollision) {
      store.dispatch(playAction({ queue: dearEarthSorryAlbum.tracks }))
      this.changeScene('monsters')
    }

    else if (this.metajazzCollision) {
      store.dispatch(playAction({ queue: metajazzAlbum.tracks }))
      this.changeScene('monsters')
    }

    else if (this.jackInTheBoxDreamsCollision) {
      store.dispatch(playAction({ queue: jackInTheBoxDreamsAlbum.tracks }))
      this.changeScene('monsters')
    }
  }
}
