import Level from '../level'

export default class DoorsLevel extends Level {
  get mapKey() {
    return 'doorsLevel'
  }

  constructor() {
    super('doors')
  }

  onPreload() {
    this.load.spritesheet('grayghost', 'tilesets/grayghost.png', this.frameDimensions)
    this.load.image('pinkDoor', 'tilesets/door/pink.png', this.frameDimensions)
    this.load.image('brownDoor', 'tilesets/door/brown.png', this.frameDimensions)
    this.load.image('purpleDoor', 'tilesets/door/purple.png', this.frameDimensions)
  }

  onCreate() {
    this.ellie.x = window.innerWidth
    this.ellie.y = window.innerHeight
    this.pinkDoor = this.matter.add.sprite(
      this.ellie.x - 200,
      this.ellie.y - 200,
      'pinkDoor',
      null,
      { isSensor: true }
    )
    this.brownDoor = this.matter.add.sprite(
      this.ellie.x + 200,
      this.ellie.y - 200,
      'brownDoor',
      null,
      { isSensor: true }
    )
    this.purpleDoor = this.matter.add.sprite(
      this.ellie.x,
      this.ellie.y + 200,
      'purpleDoor',
      null,
      { isSensor: true }
    )

    this.monitorCollisionsFor(
      'pinkDoor',
      'purpleDoor',
      'brownDoor',
    )
  }

  onUpdate (time, delta) {
  }

  onEnter = () => {
    if (this.pinkDoorCollision)
      this.changeScene('pink')

    else if (this.brownDoorCollision)
      this.changeScene('brown')

    else if (this.purpleDoorCollision)
      this.changeScene('magicFloor')
  }
}
